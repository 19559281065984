import { TaskStatus } from '@/classes/Task';

export default function getStatusColor(status: TaskStatus): string {
  switch (status) {
    case TaskStatus.Backlog:
      return '#6b7280';
    case TaskStatus.InProgress:
      return '#3b82f6';
    case TaskStatus.Done:
      return '#22c55e';
    case TaskStatus.Pending:
      return '#f59e0b';
    case TaskStatus.Cancelled:
      return '#ef4444';
    case TaskStatus.InReview:
      return '#a855f7';
    default:
      return '';
  }
}
