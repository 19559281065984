import { AddTaskDialog } from '@/dialogs/AddTaskDialog';
import { AddDeveloperDialog } from '@/dialogs/AddDeveloperDialog';
import { AddPTODialog } from '@/dialogs/AddPTODialog';
import { EditChartDialog } from '@/dialogs/EditChartDialog';
import { ExportChartDialog } from '@/dialogs/ExportChartDialog';
import { ImportChartDialog } from '@/dialogs/ImportChartDialog';
import { EditTaskDialog } from '@/dialogs/EditTaskDialog';
import { EditPTODialog } from '@/dialogs/EditPTODialog';
import { EditDeveloperDialog } from '@/dialogs/EditDeveloperDialog';
import { ImportTaskDialog, ImportTaskData } from '@/dialogs/ImportTaskDialog';
import { ImportAssigneeListDialog } from '@/dialogs/ImportAssigneeListDialog';

import saveChartToStorage from '@/utilities/saveChartToStorage';
import getStatusClass from '@/utilities/getStatusClass';

import { Developer, PTOType } from './Developer';
import { Task, TaskData, TaskStatus } from './Task';
import {
  ADD_DEVELOPER_BUTTON_TEXT,
  ADD_PTO_BUTTON_TEXT,
  ADD_TASK_BUTTON_LABEL,
  CHART_EDIT_BUTTON_LABEL,
  CHART_EXPORT_BUTTON_LABEL,
  CHART_IMPORT_BUTTON_LABEL,
  CHART_SAVE_BUTTON_LABEL,
  DEVELOPER_DELETE_CONFIRMATION_TEXT,
  DEVELOPER_DELETE_ERROR_TEXT,
  DEVELOPER_IMPORT_BUTTON_LABEL,
  GANTT_ROW_BARS_CLASS,
  GANTT_ROW_CLASS,
  GANTT_ROW_FIRST_CLASS,
  GANTT_ROW_LINES_CLASS,
  GANTT_ROW_MONTHS_CLASS,
  HOLIDAYS_HUNGARY_ARRAY,
  PTO_DELETE_CONFIRMATION_TEXT,
  PTO_TYPE_PTO_LABEL,
  PTO_TYPE_SICK_LABEL,
  TASK_DELETE_CONFIRMATION_TEXT,
  TASK_IMPORT_BUTTON_LABEL,
} from '@/constants';
import convertStringToChart from '@/utilities/convertStringToChart';
import generateDaysArray from '@/utilities/generateDaysArray';
import generateWeeksArray from '@/utilities/generateWeeksArray';
import isSameDay from '@/utilities/isSameDay';
import { log } from 'console';

export interface ChartData {
  container: HTMLElement;
  timestamp: number;
  name: string;
  start: Date;
  end: Date;
  developers: Developer[];
}

export class Chart {
  private container: HTMLElement;
  private name: string;
  private start: Date;
  private end: Date;
  private developers: Developer[];
  private timestamp: number;

  private addDeveloperDialog: AddDeveloperDialog;
  private addTaskDialog: AddTaskDialog;
  private addPTODialog: AddPTODialog;
  private editChartDialog: EditChartDialog;
  private exportChartDialog: ExportChartDialog;
  private importChartDialog: ImportChartDialog;
  private editTaskDialog: EditTaskDialog;
  private editPTODialog: EditPTODialog;
  private editDeveloperDialog: EditDeveloperDialog;
  private importTaskDialog: ImportTaskDialog;
  private importAssigneeListDialog: ImportAssigneeListDialog;

  constructor(data: ChartData) {
    this.container = data.container;
    this.name = data.name;
    this.start = data.start;
    this.end = data.end;
    this.developers = data.developers || [];
    this.timestamp = data.timestamp || 0;

    this.addTaskDialog = new AddTaskDialog(
      this.container,
      this.developers,
      this.start,
      this.end,
      (taskData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === taskData.developerId,
        );

        if (developer) {
          const { developerId, ...taskDataWithoutId } = taskData;
          developer.addTask(new Task(taskDataWithoutId));
          this.render();

          this.exportChartDialog.updateURLInput(this);
        }
      },
    );

    this.addDeveloperDialog = new AddDeveloperDialog(
      this.container,
      (developerName) => {
        this.developers.push(
          new Developer({ name: developerName, tasks: [], ptos: [] }),
        );
        this.render();
        this.updateAllDialogs();
      },
    );

    this.addPTODialog = new AddPTODialog(
      this.container,
      this.developers,
      this.start,
      this.end,
      (ptoData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === ptoData.developerId,
        );

        if (developer) {
          developer.addPTO(ptoData);

          this.render();

          this.exportChartDialog.updateURLInput(this);
        }
      },
    );

    this.editChartDialog = new EditChartDialog(
      this.container,
      this.name,
      this.start,
      this.end,
      (data) => {
        this.name = data.name;
        this.start = data.start;
        this.end = data.end;
        this.render();

        this.exportChartDialog.updateURLInput(this);
      },
    );

    this.editTaskDialog = new EditTaskDialog(
      this.container,
      this.start,
      (taskData) => {
        this.developers.forEach((developer) => {
          const task = developer
            .getTasks()
            .find((task) => task.getId() === taskData.id);

          if (task) {
            if (
              taskData.delete &&
              window.confirm(TASK_DELETE_CONFIRMATION_TEXT)
            ) {
              developer.removeTask(taskData.id);
              this.render();
              return;
            }

            task.setName(taskData.name);
            task.setLink(taskData.link);
            task.setStart(taskData.start);
            task.setEnd(taskData.end);
            task.setStatus(taskData.status);

            this.render();
          }
        });

        this.exportChartDialog.updateURLInput(this);
      },
    );

    this.editPTODialog = new EditPTODialog(this.container, (ptoData) => {
      const developer = this.developers.find(
        (developer) => developer.getId() === ptoData.developerId,
      );

      const pto = developer?.getPTOs()[ptoData.ptoIndex];

      if (pto) {
        if (ptoData.delete && window.confirm(PTO_DELETE_CONFIRMATION_TEXT)) {
          developer.removePTO(ptoData.ptoIndex);
          this.render();
          return;
        }

        pto.start = ptoData.start;
        pto.end = ptoData.end;
        pto.type = ptoData.type as PTOType;

        this.render();
      }

      this.exportChartDialog.updateURLInput(this);
    });

    this.editDeveloperDialog = new EditDeveloperDialog(
      this.container,
      (developerData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === developerData.id,
        );

        if (developer) {
          if (
            developerData.delete &&
            window.confirm(DEVELOPER_DELETE_CONFIRMATION_TEXT)
          ) {
            if (
              developer.getTasks().length > 0 ||
              developer.getPTOs().length > 0
            ) {
              alert(DEVELOPER_DELETE_ERROR_TEXT);
              return;
            }

            this.developers = this.developers.filter(
              (developer) => developer.getId() !== developerData.id,
            );
            this.render();
            this.updateAllDialogs();
            return;
          }

          developer.setName(developerData.name);

          this.render();
          this.updateAllDialogs();
        }
      },
    );

    this.exportChartDialog = new ExportChartDialog(this.container, this);
    this.importChartDialog = new ImportChartDialog(this.container, (data) => {
      try {
        const chart = convertStringToChart(data);
        this.name = chart.getName();
        this.start = chart.getStart();
        this.end = chart.getEnd();
        this.developers = chart.getDevelopers();
        this.timestamp = chart.getTimestamp();

        this.render();
        this.exportChartDialog.updateURLInput(this);
      } catch (error) {
        console.error('Failed to import chart:', error);
        alert('Failed to import chart. Please check the format and try again.');
      }
    });

    this.importTaskDialog = new ImportTaskDialog(
      this.container,
      (taskData: ImportTaskData) => {
        const developer = this.developers.find((developer) =>
          taskData.developerNames.some((name) => developer.getName() === name),
        );

        if (developer) {
          this.addTaskDialog.openDialogWithData({
            developerId: developer.getId(),
            ...taskData,
          });
        } else {
          alert(
            'Developer not found. Please check the developer name and try again.',
          );
        }
      },
    );

    this.importAssigneeListDialog = new ImportAssigneeListDialog(
      this.container,
      (developerNames: string[]) => {
        developerNames.forEach((name) => {
          if (!this.developers.some((dev) => dev.getName() === name)) {
            this.developers.push(new Developer({ name, tasks: [], ptos: [] }));
          }
        });
        this.render();
        this.updateAllDialogs();
      },
    );
  }

  private clear(): void {
    this.container.innerHTML = '';
  }

  private renderAllDialogs(): void {
    this.addDeveloperDialog.render();
    this.addTaskDialog.render();
    this.addPTODialog.render();
    this.editChartDialog.render();
    this.editTaskDialog.render();
    this.editPTODialog.render();
    this.editDeveloperDialog.render();
    this.exportChartDialog.render();
    this.importChartDialog.render();
    this.importTaskDialog.render();
    this.importAssigneeListDialog.render();
  }

  private updateAllDialogs(): void {
    this.addTaskDialog.updateDevelopers(this.developers);
    this.addPTODialog.updateDevelopers(this.developers);
    this.exportChartDialog.updateURLInput(this);
  }

  public render(): void {
    this.clear();
    const fragment = document.createDocumentFragment();
    fragment.appendChild(this.generateChartHeader());
    fragment.appendChild(this.generateButtons());
    this.renderAllDialogs();
    const chartContainer = this.generateChartContainer();
    fragment.appendChild(chartContainer);
    this.container.appendChild(fragment);
  }

  private generateChartContainer(): HTMLElement {
    const daysArray = generateDaysArray(this.start, this.end);
    const columnCount = daysArray.length + 1;

    const chartContainer = document.createElement('div');
    chartContainer.classList.add('gantt');
    chartContainer.appendChild(this.generateHeader(daysArray));
    chartContainer.appendChild(this.generateLines(daysArray, columnCount));
    this.developers.forEach((developer) => {
      chartContainer.appendChild(
        this.generateDeveloperRow(developer, daysArray),
      );
    });

    return chartContainer;
  }

  private generateHeader(daysArray: Date[]): HTMLElement {
    const header = document.createElement('div');
    header.classList.add(GANTT_ROW_CLASS, GANTT_ROW_MONTHS_CLASS);
    header.style.gridTemplateColumns = `150px repeat(${daysArray.length}, 1fr)`;
    const headerFirstField = document.createElement('div');
    headerFirstField.classList.add(GANTT_ROW_FIRST_CLASS);
    header.appendChild(headerFirstField);

    generateWeeksArray(this.start, this.end).forEach((week) => {
      const weekElement = document.createElement('span');
      weekElement.innerHTML = week.name;
      weekElement.style.gridColumn = 'span 5';
      weekElement.style.borderLeft = '1px solid black';
      header.appendChild(weekElement);
    });
    return header;
  }

  private generateLines(daysArray: Date[], columnCount: number): HTMLElement {
    const lines = document.createElement('div');
    lines.classList.add(GANTT_ROW_CLASS, GANTT_ROW_LINES_CLASS);
    lines.style.gridTemplateColumns = `150px repeat(${daysArray.length}, 1fr)`;

    for (let i = 0; i < columnCount; i++) {
      const dayElement = document.createElement('span');
      const day = daysArray[i - 1];
      if (day && day.toDateString() === new Date().toDateString()) {
        dayElement.classList.add('marker');
      }
      HOLIDAYS_HUNGARY_ARRAY.forEach((holiday) => {
        if (day && isSameDay(day, new Date(holiday))) {
          dayElement.classList.add('holiday', 'stripes');
        }
      });
      lines.appendChild(dayElement);
    }
    return lines;
  }

  private generateDeveloperRow(
    developer: Developer,
    daysArray: Date[],
  ): HTMLElement {
    const developerWrapperElement = document.createElement('div');
    developerWrapperElement.classList.add(GANTT_ROW_CLASS);

    const developerName = document.createElement('div');
    developerName.classList.add(GANTT_ROW_FIRST_CLASS);
    developerName.innerHTML = developer.getName();
    developerName.addEventListener('click', () => {
      this.editDeveloperDialog.openDialogWithData(
        developer.getId(),
        developer.getName(),
      );
    });
    developerWrapperElement.appendChild(developerName);

    const developerTasks = document.createElement('ul');
    developerTasks.classList.add(GANTT_ROW_BARS_CLASS);
    developerTasks.style.gridTemplateColumns = `repeat(${daysArray.length}, 1fr)`;
    developerWrapperElement.appendChild(developerTasks);

    developer.getTasks().forEach((task) => {
      developerTasks.appendChild(this.generateTaskElement(task, daysArray));
    });
    developer.getPTOs().forEach((pto) => {
      developerTasks.appendChild(
        this.generatePTOElement(pto, daysArray, developer),
      );
    });
    return developerWrapperElement;
  }

  private generateTaskElement(task: Task, daysArray: Date[]): HTMLElement {
    const startDay =
      daysArray.findIndex((day) => isSameDay(day, task.getStart())) + 1;
    const endDay =
      daysArray.findIndex((day) => isSameDay(day, task.getEnd())) + 2;
    const taskElement = document.createElement('li');
    taskElement.style.gridColumn = `${startDay} / ${endDay}`;
    taskElement.classList.add(getStatusClass(task.getStatus()));
    taskElement.innerHTML = task.getName();

    const statusHistoryGradient =
      this.editTaskDialog.generateStatusHistoryGradient(
        task.getStatusHistory(),
        task.getStart(),
        task.getEnd(),
      );
    taskElement.addEventListener('mouseenter', () => {
      taskElement.style.backgroundImage = statusHistoryGradient;
    });
    taskElement.addEventListener('mouseleave', () => {
      taskElement.style.backgroundImage = '';
    });

    taskElement.addEventListener('click', () => {
      this.editTaskDialog.openDialogWithData({
        id: task.getId(),
        name: task.getName(),
        link: task.getLink(),
        start: task.getStart(),
        end: task.getEnd(),
        status: task.getStatus(),
        min: this.start,
        max: this.end,
        statusHistory: task.getStatusHistory(),
      });
    });
    taskElement.addEventListener('mousedown', function (event) {
      if (event.button === 1) {
        window.open(task.getLink(), '_blank');
      }
    });
    return taskElement;
  }

  private generatePTOElement(
    pto: any,
    daysArray: Date[],
    developer: Developer,
  ): HTMLElement {
    const startDay =
      daysArray.findIndex((day) => isSameDay(day, pto.start)) + 1;
    const endDay = daysArray.findIndex((day) => isSameDay(day, pto.end)) + 2;
    const ptoElement = document.createElement('li');
    ptoElement.classList.add('stripes');
    if (pto.type === PTOType.Sick) {
      ptoElement.classList.add('orange');
      ptoElement.innerHTML = PTO_TYPE_SICK_LABEL;
    } else {
      ptoElement.classList.add('red');
      ptoElement.innerHTML = PTO_TYPE_PTO_LABEL;
    }
    ptoElement.style.gridColumn = `${startDay} / ${endDay}`;
    ptoElement.addEventListener('click', () => {
      this.editPTODialog.openDialogWithData({
        developerId: developer.getId(),
        ptoIndex: developer.getPTOs().indexOf(pto),
        type: pto.type,
        start: pto.start,
        end: pto.end,
        min: this.start,
        max: this.end,
      });
    });
    return ptoElement;
  }

  public getDevelopers(): Developer[] {
    return this.developers;
  }

  public getName(): string {
    return this.name;
  }

  public getStart(): Date {
    return this.start;
  }

  public getEnd(): Date {
    return this.end;
  }

  public getTimestamp(): number {
    return this.timestamp;
  }

  public getFormattedTimestamp(): string {
    return this.timestamp > 0 ? new Date(this.timestamp).toLocaleString() : '';
  }

  private generateChartHeader(): HTMLElement {
    const headerContainer = document.createElement('div');
    headerContainer.classList.add('gantt__header');

    const nameElement = document.createElement('h1');
    nameElement.classList.add('gantt__header-name');
    nameElement.innerText = this.name;

    const timestampElement = document.createElement('span');
    timestampElement.classList.add('gantt__header-timestamp');
    timestampElement.innerText = this.getFormattedTimestamp();

    headerContainer.appendChild(nameElement);
    headerContainer.appendChild(timestampElement);

    return headerContainer;
  }

  private generateButtons(): HTMLElement {
    const buttons = document.createElement('div');
    buttons.classList.add('gantt__buttons');

    const leftButtons = document.createElement('div');
    leftButtons.classList.add('gantt__buttons-left');
    buttons.appendChild(leftButtons);

    const rightButtons = document.createElement('div');
    rightButtons.classList.add('gantt__buttons-right');
    buttons.appendChild(rightButtons);

    const addDeveloperButton = this.createButton(
      ADD_DEVELOPER_BUTTON_TEXT,
      () => {
        this.addDeveloperDialog.openDialog();
      },
    );
    leftButtons.appendChild(addDeveloperButton);

    const importAssigneeListButton = this.createButton(
      DEVELOPER_IMPORT_BUTTON_LABEL,
      () => {
        this.importAssigneeListDialog.openDialog();
      },
    );
    leftButtons.appendChild(importAssigneeListButton);

    const addTaskButton = this.createButton(ADD_TASK_BUTTON_LABEL, () => {
      this.addTaskDialog.openDialog();
    });
    leftButtons.appendChild(addTaskButton);

    const importTaskButton = this.createButton(TASK_IMPORT_BUTTON_LABEL, () => {
      this.importTaskDialog.openDialog();
    });
    leftButtons.appendChild(importTaskButton);

    const addPTOButton = this.createButton(ADD_PTO_BUTTON_TEXT, () => {
      this.addPTODialog.openDialog();
    });
    leftButtons.appendChild(addPTOButton);

    const editPIButton = this.createButton(CHART_EDIT_BUTTON_LABEL, () => {
      this.editChartDialog.openDialog();
    });
    rightButtons.appendChild(editPIButton);

    const saveButton = this.createButton(CHART_SAVE_BUTTON_LABEL, () => {
      saveChartToStorage(this);

      const timestampElement: HTMLElement | null = document.querySelector(
        '.gantt__header-timestamp',
      );
      if (timestampElement) {
        this.timestamp = Date.now();
        timestampElement.innerText = this.getFormattedTimestamp();
      }
    });
    rightButtons.appendChild(saveButton);

    const exportButton = this.createButton(CHART_EXPORT_BUTTON_LABEL, () => {
      this.exportChartDialog.openDialog();
    });
    rightButtons.appendChild(exportButton);

    const importButton = this.createButton(CHART_IMPORT_BUTTON_LABEL, () => {
      this.importChartDialog.openDialog();
    });
    rightButtons.appendChild(importButton);

    return buttons;
  }

  private createButton(
    text: string,
    callback: {
      (): void;
      (): void;
      (this: HTMLButtonElement, ev: MouseEvent): any;
    },
  ) {
    const button = document.createElement('button');
    button.innerHTML = text;
    button.classList.add('button');
    button.addEventListener('click', callback);
    return button;
  }
}
