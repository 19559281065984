import { AbstractDialog } from './AbstractDialog';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import convertStringToChart from '@/utilities/convertStringToChart';
import { DEVELOPER_IMPORT_BUTTON_LABEL } from '@/constants';

export class ImportAssigneeListDialog extends AbstractDialog {
  private callback: (developerNames: string[]) => void;

  constructor(
    container: HTMLElement,
    callback: (developerNames: string[]) => void,
  ) {
    super(container);
    this.callback = callback;

    const formElements: FormElement[] = [
      {
        type: 'textarea',
        attributes: {
          placeholder: 'Paste the encoded chart string here...',
          rows: '20',
        },
      },
      {
        type: 'button',
        attributes: { type: 'button' },
        content: DEVELOPER_IMPORT_BUTTON_LABEL,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const textarea = this.dialog.querySelector(
      'textarea',
    ) as HTMLTextAreaElement;
    const importButton = this.dialog.querySelector(
      'button',
    ) as HTMLButtonElement;

    importButton.addEventListener('click', () => {
      const encodedString = textarea.value;
      try {
        const chart = convertStringToChart(encodedString);
        const developerNames = chart
          .getDevelopers()
          .map((dev: any) => dev.name);
        this.callback(developerNames);
        textarea.value = '';
        this.closeDialog();
      } catch (error) {
        alert(
          'Failed to import assignee list. Please check the format and try again.',
        );
      }
    });
  }
}
