/**
 * Calculates the duration in days between two dates.
 *
 * @param start - The start date.
 * @param end - The end date.
 * @returns The duration in days between the start and end dates.
 */
export default function calculateDurationInDays(
  start: Date,
  end: Date,
): number {
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const diff = end.getTime() - start.getTime();
  return diff / millisecondsInADay;
}
