/**
 * Generate an array of dates between two dates excluding weekends
 *
 * @param start Date
 * @param end Date
 * @returns Date[]
 */
export default function generateDaysArray(start: Date, end: Date): Date[] {
  const dates = [];
  let currentDate = new Date(start);

  while (currentDate <= end) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      dates.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
