import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import { AbstractDialog } from './AbstractDialog';
import {
  DEVELOPER_DELETE_BUTTON_ID,
  DEVELOPER_DELETE_BUTTON_LABEL,
  DEVELOPER_EDIT_BUTTON_LABEL,
  DEVELOPER_ID_ID,
  DEVELOPER_NAME_ID,
  DEVELOPER_NAME_LABEL,
} from '@/constants';

export interface EditDeveloperData {
  id: string;
  name: string;
  delete?: boolean;
}

export class EditDeveloperDialog extends AbstractDialog {
  constructor(
    container: HTMLElement,
    callback: (data: EditDeveloperData) => void,
  ) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'hidden',
          id: DEVELOPER_ID_ID,
          name: DEVELOPER_ID_ID,
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'text',
          id: DEVELOPER_NAME_ID,
          name: DEVELOPER_NAME_ID,
          autocomplete: 'off',
          autofocus: 'true',
          required: 'true',
          label: DEVELOPER_NAME_LABEL,
        },
      },
      {
        type: 'buttonGroup',
        children: [
          {
            type: 'button',
            attributes: {
              type: 'button',
              id: DEVELOPER_DELETE_BUTTON_ID,
            },
            content: DEVELOPER_DELETE_BUTTON_LABEL,
          },
          {
            type: 'button',
            attributes: {
              type: 'submit',
              style: 'flex-grow: 1;',
            },
            content: DEVELOPER_EDIT_BUTTON_LABEL,
          },
        ],
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const developerNameInput = this.dialog.querySelector(
      `#${DEVELOPER_NAME_ID}`,
    ) as HTMLInputElement;
    const developerIdInput = this.dialog.querySelector(
      `#${DEVELOPER_ID_ID}`,
    ) as HTMLInputElement;

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();
      callback({
        id: developerIdInput.value,
        name: developerNameInput.value,
      });
      this.closeDialog();
    });

    const deleteButton = this.dialog.querySelector(
      `#${DEVELOPER_DELETE_BUTTON_ID}`,
    ) as HTMLInputElement;

    deleteButton.addEventListener('click', () => {
      callback({
        id: developerIdInput.value,
        name: developerNameInput.value,
        delete: true,
      });
      this.closeDialog();
    });
  }

  openDialogWithData(developerId: string, developerName: string) {
    const developerNameInput = this.dialog.querySelector(
      `#${DEVELOPER_NAME_ID}`,
    ) as HTMLInputElement;
    const developerIdInput = this.dialog.querySelector(
      `#${DEVELOPER_ID_ID}`,
    ) as HTMLInputElement;

    developerNameInput.value = developerName;
    developerIdInput.value = developerId;

    this.openDialog();
  }
}
