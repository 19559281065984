import { AbstractDialog } from './AbstractDialog';
import { TaskData, TaskStatus } from '@/classes/Task';
import { TASK_IMPORT_BUTTON_LABEL } from '@/constants';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import transformDeveloperName from '@/utilities/transformDeveloperName';

export type ImportTaskData = TaskData & { developerNames: string[] };
type ParsedInputData = Partial<ImportTaskData> & { dateString?: string };

export class ImportTaskDialog extends AbstractDialog {
  private callback: (taskData: ImportTaskData) => void;

  constructor(
    container: HTMLElement,
    callback: (taskData: ImportTaskData) => void,
  ) {
    super(container);
    this.callback = callback;

    const formElements: FormElement[] = [
      {
        type: 'textarea',
        attributes: {
          placeholder: 'Paste Jira HTML or pipe-separated task string here...',
          rows: '20',
        },
      },
      {
        type: 'button',
        attributes: { type: 'button' },
        content: TASK_IMPORT_BUTTON_LABEL,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const textarea = this.dialog.querySelector(
      'textarea',
    ) as HTMLTextAreaElement;
    const importButton = this.dialog.querySelector(
      'button',
    ) as HTMLButtonElement;

    importButton.addEventListener('click', () => {
      const input = textarea.value.trim();
      if (input) {
        const taskData = this.parseInput(input);
        if (taskData) {
          this.callback(taskData);
          textarea.value = '';
          this.closeDialog();
        } else {
          alert(
            'Failed to parse input. Please check the format and try again.',
          );
        }
      }
    });
  }

  private parseInput(input: string): ImportTaskData | null {
    const parsedInputData = input.includes('<body')
      ? this.parseJiraHtml(input)
      : this.parsePipeSeparatedString(input);

    if (!parsedInputData) {
      return null;
    }

    const startDate = new Date(
      parsedInputData.dateString ? parsedInputData.dateString : new Date(),
    );
    const endDate = new Date(startDate);
    const dayOfWeek = startDate.getDay();
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
    endDate.setDate(startDate.getDate() + daysUntilFriday + 7);

    return {
      start: startDate,
      end: endDate,
      status: TaskStatus.Backlog,
      ...parsedInputData,
    } as ImportTaskData;
  }

  private parsePipeSeparatedString(input: string): ParsedInputData | null {
    const parts = input.split('|');
    if (parts.length !== 4) {
      return null;
    }

    const [link, name, developerName, dateString] = parts;
    const developerNames = transformDeveloperName(developerName);

    return {
      link,
      name,
      developerNames,
      dateString,
    };
  }

  private parseJiraHtml(html: string): ParsedInputData {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const issueKey = doc.querySelector('#key-val')?.textContent?.trim();
    const href = doc.querySelector('#key-val')?.getAttribute('href');
    const baseURL = (
      doc.querySelector('input[title="baseURL"]') as HTMLInputElement
    )?.value;
    const baseName = new URL(baseURL).hostname;
    let summary = doc.querySelector('#summary-val h2')?.textContent?.trim();
    if (summary) {
      summary = summary.replace(/\r?\n|\r/g, ' ');
    }
    const assignee = doc
      .querySelector('#assignee-val .user-hover')
      ?.textContent?.trim();
    const developerNames = transformDeveloperName(assignee || '');

    return {
      link: `https://${baseName}${href}`,
      name: `${issueKey} ${summary}`,
      developerNames,
    };
  }
}
