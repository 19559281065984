import convertStringToChart from './convertStringToChart';

/**
 * Loads chart data from the query string of the current URL.
 *
 * This function extracts the 'chart' parameter from the query string,
 * removes it from the URL, and then converts the parameter value to a chart object.
 *
 * @returns {Chart | undefined} The chart object if the 'chart' parameter is present, otherwise undefined.
 */
export default function loadChartFromQueryString() {
  const params = new URLSearchParams(location.search);
  const chartData = params.get('chart');

  if (chartData) {
    const url = new URL(location.href);
    url.searchParams.delete('chart');
    history.replaceState(null, '', url.toString());

    return convertStringToChart(chartData);
  }
}
