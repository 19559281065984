import { TaskStatus } from '@/classes/Task';

export default function getStatusClass(status: TaskStatus): string {
  switch (status) {
    case TaskStatus.Backlog:
      return 'gray';
    case TaskStatus.InProgress:
      return 'blue';
    case TaskStatus.Done:
      return 'green';
    case TaskStatus.Pending:
      return 'orange';
    case TaskStatus.Cancelled:
      return 'red';
    case TaskStatus.InReview:
      return 'purple';
    default:
      return '';
  }
}
