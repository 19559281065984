/**
 * Resets the chart by removing the 'chart' item from local storage and reloading the page.
 *
 * @remarks
 * This function clears the chart data stored in the browser's local storage and forces a page reload
 * to ensure that the chart is reset to its initial state.
 *
 * @example
 * ```typescript
 * resetChart();
 * ```
 */
export default function resetChart(): void {
  localStorage.removeItem('chart');
  location.reload();
}
