export const CHART_NAME_ID = 'chart-name';
export const CHART_NAME_LABEL = 'Chart Name';
export const CHART_START_ID = 'chart-start';
export const CHART_START_LABEL = 'Chart Start';
export const CHART_END_ID = 'chart-end';
export const CHART_END_LABEL = 'Chart End';
export const CHART_DELETE_BUTTON_ID = 'delete-chart-button';
export const CHART_DELETE_BUTTON_LABEL = '🗑️';
export const CHART_DELETE_CONFIRMATION_TEXT =
  'Do you really want to reset this chart?';
export const CHART_EDIT_BUTTON_LABEL = '✏️ Edit Chart';
export const CREATE_CHART_FORM_ID = 'create-chart-form';
export const CHART_SAVE_BUTTON_LABEL = '💾 Save Chart';
export const CHART_EXPORT_BUTTON_LABEL = '📤 Export Chart';
export const CHART_EXPORT_FIELD_ID = 'export-field';
export const CHART_EXPORT_COPY_BUTTON_ID = 'copy-button';
export const CHART_EXPORT_COPY_BUTTON_TEXT = '📋 Copy';
export const CHART_IMPORT_BUTTON_LABEL = '📥 Import Chart';
export const CHART_IMPORT_FIELD_ID = 'import-field';
export const CHART_IMPORT_BUTTON_ID = 'import-button';
export const CHART_IMPORT_BUTTON_TEXT = '📥 Import Chart';

export const CREATE_CHART_BUTTON_TEXT = '➕ Create Chart';
export const CREATE_CHART_FORM_TITLE = 'Create a New Gantt Chart';

export const DEVELOPER_SELECT_ID = 'developer-select';
export const DEVELOPER_LABEL = 'Developer';
export const TASK_ID_ID = 'task-id';
export const TASK_NAME_ID = 'task-name';
export const TASK_NAME_LABEL = 'Task Name';
export const TASK_LINK_ID = 'task-link';
export const TASK_LINK_LABEL = 'Task Link';
export const TASK_START_ID = 'task-start';
export const TASK_START_LABEL = 'Task Start';
export const TASK_END_ID = 'task-end';
export const TASK_END_LABEL = 'Task End';
export const TASK_STATUS_ID = 'task-status';
export const TASK_STATUS_LABEL = 'Task Status';
export const TASK_STATUS_BACKLOG_VALUE = 'backlog';
export const TASK_STATUS_BACKLOG_LABEL = 'Backlog';
export const TASK_STATUS_IN_PROGRESS_VALUE = 'in-progress';
export const TASK_STATUS_IN_PROGRESS_LABEL = 'In Progress';
export const TASK_STATUS_DONE_VALUE = 'done';
export const TASK_STATUS_DONE_LABEL = 'Done';
export const TASK_STATUS_PENDING_VALUE = 'pending';
export const TASK_STATUS_PENDING_LABEL = 'Pending';
export const TASK_STATUS_CANCELLED_VALUE = 'cancelled';
export const TASK_STATUS_CANCELLED_LABEL = 'Cancelled';
export const TASK_STATUS_IN_REVIEW_VALUE = 'in-review';
export const TASK_STATUS_IN_REVIEW_LABEL = 'In Review';
export const TASK_STATUS_OPTIONS = [
  { value: TASK_STATUS_BACKLOG_VALUE, text: TASK_STATUS_BACKLOG_LABEL },
  { value: TASK_STATUS_IN_PROGRESS_VALUE, text: TASK_STATUS_IN_PROGRESS_LABEL },
  { value: TASK_STATUS_DONE_VALUE, text: TASK_STATUS_DONE_LABEL },
  { value: TASK_STATUS_PENDING_VALUE, text: TASK_STATUS_PENDING_LABEL },
  { value: TASK_STATUS_CANCELLED_VALUE, text: TASK_STATUS_CANCELLED_LABEL },
  { value: TASK_STATUS_IN_REVIEW_VALUE, text: TASK_STATUS_IN_REVIEW_LABEL },
];
export const TASK_EDIT_BUTTON_LABEL = '💾 Save Task';
export const TASK_DELETE_BUTTON_LABEL = '🗑️';
export const TASK_DELETE_BUTTON_ID = 'delete-task-button';
export const TASK_DELETE_CONFIRMATION_TEXT =
  'Do you really want to delete this task?';
export const ADD_TASK_BUTTON_LABEL = '➕ Add Task';
export const TASK_IMPORT_BUTTON_LABEL = '📥 Import Task';

export const DEVELOPER_ID_ID = 'developer-id';
export const DEVELOPER_NAME_ID = 'developer-name';
export const DEVELOPER_NAME_LABEL = 'Assignee Name';
export const DEVELOPER_DELETE_BUTTON_ID = 'delete-developer-button';
export const DEVELOPER_DELETE_BUTTON_LABEL = '🗑️';
export const DEVELOPER_DELETE_CONFIRMATION_TEXT =
  'Do you really want to delete this assignee?';
export const DEVELOPER_DELETE_ERROR_TEXT =
  'Cannot delete an assignee with assigned tasks or PTOs';
export const DEVELOPER_EDIT_BUTTON_LABEL = '✏️ Edit Assignee';

export const ADD_DEVELOPER_BUTTON_TEXT = '➕ Add Assignee';
export const DEVELOPER_IMPORT_BUTTON_LABEL = '📥 Import Assignees';

export const PTO_START_ID = 'start-pto';
export const PTO_START_LABEL = 'Start';
export const PTO_END_ID = 'end-pto';
export const PTO_END_LABEL = 'End';
export const PTO_INDEX_ID = 'pto-index';
export const ADD_PTO_BUTTON_TEXT = '➕ Add Leave';
export const EDIT_PTO_BUTTON_TEXT = '💾 Save Leave';
export const DELETE_PTO_BUTTON_ID = 'delete-pto-button';
export const DELETE_PTO_BUTTON_TEXT = '🗑️';
export const PTO_DELETE_CONFIRMATION_TEXT =
  'Do you really want to delete this PTO?';
export const PTO_TYPE_ID = 'pto-type';
export const PTO_TYPE_LABEL = 'Leave Type';
export const PTO_TYPE_PTO_VALUE = 'pto';
export const PTO_TYPE_PTO_LABEL = 'PTO';
export const PTO_TYPE_SICK_VALUE = 'sick';
export const PTO_TYPE_SICK_LABEL = 'Sick Leave';
export const PTO_TYPE_OPTIONS = [
  { value: PTO_TYPE_PTO_VALUE, text: PTO_TYPE_PTO_LABEL },
  { value: PTO_TYPE_SICK_VALUE, text: PTO_TYPE_SICK_LABEL },
];

export const HOLIDAYS_HUNGARY_ARRAY = [
  '2024-01-01',
  '2024-03-15',
  '2024-04-29',
  '2024-04-01',
  '2024-05-01',
  '2024-05-20',
  '2024-08-19',
  '2024-08-20',
  '2024-10-23',
  '2024-11-01',
  '2024-12-24',
  '2024-12-25',
  '2024-12-26',
  '2024-12-27',
  '2025-01-01',
  '2025-03-15',
  '2025-04-18',
  '2025-04-21',
  '2025-05-01',
  '2025-05-02',
  '2025-06-09',
  '2025-08-20',
  '2025-10-23',
  '2025-10-24',
  '2025-11-01',
  '2025-12-24',
  '2025-12-25',
  '2025-12-26',
];

export const GANTT_ROW_CLASS = 'gantt__row';
export const GANTT_ROW_FIRST_CLASS = GANTT_ROW_CLASS + '-first';
export const GANTT_ROW_BARS_CLASS = GANTT_ROW_CLASS + '-bars';
export const GANTT_ROW_MONTHS_CLASS = GANTT_ROW_CLASS + '--months';
export const GANTT_ROW_LINES_CLASS = GANTT_ROW_CLASS + '--lines';
