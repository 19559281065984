import { Chart } from '@/classes/Chart';
import toBinaryStr from './toBinaryStr';

/**
 * Converts a Chart object to a base64-encoded string representation.
 *
 * The resulting string includes the chart's name, start and end dates,
 * and a list of developers with their respective tasks and PTOs.
 * Each task includes its name, link, start and end dates, and status.
 *
 * @param {Chart} chart - The Chart object to convert.
 * @returns {string} - The base64-encoded string representation of the chart.
 */
export default function convertChartToString(chart: Chart): string {
  const developers = chart.getDevelopers();

  return btoa(
    toBinaryStr(
      JSON.stringify({
        timestamp: chart.getTimestamp(),
        name: chart.getName(),
        start: chart.getStart().toISOString(),
        end: chart.getEnd().toISOString(),
        developers: developers.map((developer) => ({
          name: developer.getName(),
          tasks: developer.getTasks().map((task) => ({
            name: task.getName(),
            link: task.getLink(),
            start: task.getStart().toISOString(),
            end: task.getEnd().toISOString(),
            status: task.getStatus(),
            statusHistory: task.getStatusHistory()
              ? task.getStatusHistory().map((history) => ({
                  status: history.status,
                  timestamp: history.timestamp,
                }))
              : [],
          })),
          ptos: developer.getPTOs().map((pto) => ({
            start: pto.start.toISOString(),
            end: pto.end.toISOString(),
            type: pto.type,
          })),
        })),
      }),
    ),
  );
}
