import { Chart } from '@/classes/Chart';

import { AbstractDialog } from './AbstractDialog';

import convertChartToString from '@/utilities/convertChartToString';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import {
  CHART_EXPORT_COPY_BUTTON_ID,
  CHART_EXPORT_COPY_BUTTON_TEXT,
  CHART_EXPORT_FIELD_ID,
} from '@/constants';

export class ExportChartDialog extends AbstractDialog {
  constructor(container: HTMLElement, chart: Chart) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'textarea',
        attributes: {
          id: CHART_EXPORT_FIELD_ID,
          rows: '20',
          readonly: 'true',
        },
      },
      {
        type: 'button',
        attributes: {
          type: 'button',
          id: CHART_EXPORT_COPY_BUTTON_ID,
        },
        content: CHART_EXPORT_COPY_BUTTON_TEXT,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const shareUrlInput = this.dialog.querySelector(
      `#${CHART_EXPORT_FIELD_ID}`,
    ) as HTMLInputElement;
    shareUrlInput.value = convertChartToString(chart);
    shareUrlInput.addEventListener('click', () => {
      shareUrlInput.select();
    });

    const copyButton = this.dialog.querySelector(
      `#${CHART_EXPORT_COPY_BUTTON_ID}`,
    ) as HTMLButtonElement;
    copyButton.addEventListener('click', () => {
      shareUrlInput.select();
      navigator.clipboard.writeText(shareUrlInput.value);
    });
  }

  updateURLInput(chart: Chart) {
    const shareUrlInput = this.dialog.querySelector(
      `#${CHART_EXPORT_FIELD_ID}`,
    ) as HTMLInputElement;
    shareUrlInput.value = convertChartToString(chart);
  }
}
