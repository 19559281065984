/**
 * Formats a given Date object into a string with the format "MM.DD".
 *
 * @param date - The Date object to format.
 * @returns A string representing the formatted date.
 */
export default function formatDate(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}`;
}
