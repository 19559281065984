import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import { AbstractDialog } from './AbstractDialog';

import {
  CHART_IMPORT_BUTTON_ID,
  CHART_IMPORT_BUTTON_TEXT,
  CHART_IMPORT_FIELD_ID,
} from '@/constants';

export class ImportChartDialog extends AbstractDialog {
  constructor(container: HTMLElement, callback: (data: string) => void) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'textarea',
        attributes: { id: CHART_IMPORT_FIELD_ID, rows: '20' },
      },
      {
        type: 'button',
        attributes: { type: 'button', id: CHART_IMPORT_BUTTON_ID },
        content: CHART_IMPORT_BUTTON_TEXT,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const importButton = this.dialog.querySelector(
      `#${CHART_IMPORT_BUTTON_ID}`,
    ) as HTMLButtonElement;

    importButton.addEventListener('click', () => {
      const importField = this.dialog.querySelector(
        `#${CHART_IMPORT_FIELD_ID}`,
      ) as HTMLInputElement;
      callback(importField.value);

      importField.value = '';
      this.closeDialog();
    });
  }
}
