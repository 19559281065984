import { v4 as uuid } from 'uuid';

export enum TaskStatus {
  Backlog = 'backlog',
  InProgress = 'in-progress',
  Done = 'done',
  Pending = 'pending',
  Cancelled = 'cancelled',
  InReview = 'in-review',
}

export interface TaskData {
  name: string;
  link: string;
  start: Date;
  end: Date;
  status: TaskStatus;
  statusHistory?: { status: TaskStatus; timestamp: number }[];
}

export class Task {
  private id: string;
  private name: string;
  private link: string;
  private start: Date;
  private end: Date;
  private status: TaskStatus;
  private statusHistory?: { status: TaskStatus; timestamp: number }[];

  constructor(data: TaskData) {
    this.id = uuid();
    this.name = data.name;
    this.link = data.link;
    this.start = data.start;
    this.end = data.end;
    this.status = data.status;
    this.statusHistory = data.statusHistory || [];
  }

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getLink(): string {
    return this.link;
  }

  public getStart(): Date {
    return this.start;
  }

  public getEnd(): Date {
    return this.end;
  }

  public getStatus(): TaskStatus {
    return this.status;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setLink(link: string): void {
    this.link = link;
  }

  public setStart(start: Date): void {
    this.start = start;
  }

  public setEnd(end: Date): void {
    this.end = end;
  }

  public setStatus(status: TaskStatus): void {
    if (this.status !== status) {
      this.statusHistory?.push({ status, timestamp: Date.now() });
      this.status = status;
    }
  }

  public getStatusHistory(): { status: TaskStatus; timestamp: number }[] {
    return this.statusHistory!;
  }
}
