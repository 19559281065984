import roundDateToWeekday from '@/utilities/roundDateToWeekday';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import getStatusClass from '@/utilities/getStatusClass';

import { TaskData, TaskStatus } from '@/classes/Task';

import { AbstractDialog } from './AbstractDialog';

import {
  TASK_NAME_ID,
  TASK_NAME_LABEL,
  TASK_LINK_ID,
  TASK_LINK_LABEL,
  TASK_START_ID,
  TASK_START_LABEL,
  TASK_END_ID,
  TASK_END_LABEL,
  TASK_STATUS_ID,
  TASK_STATUS_LABEL,
  TASK_ID_ID,
  TASK_STATUS_OPTIONS,
  TASK_EDIT_BUTTON_LABEL,
  TASK_DELETE_BUTTON_ID,
  TASK_DELETE_BUTTON_LABEL,
} from '@/constants';
import getStatusColor from '@/utilities/getStatusColor';

interface EditTaskData extends TaskData {
  id: string;
  min?: Date;
  max?: Date;
  delete?: boolean;
}

export class EditTaskDialog extends AbstractDialog {
  private chartStartDate: Date;

  constructor(
    container: HTMLElement,
    chartStartDate: Date,
    callback: (data: EditTaskData) => void,
  ) {
    super(container);
    this.chartStartDate = chartStartDate;

    const formElements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'hidden',
          id: TASK_ID_ID,
          name: TASK_ID_ID,
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_NAME_ID,
          label: TASK_NAME_LABEL,
          type: 'text',
          autocomplete: 'off',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_LINK_ID,
          label: TASK_LINK_LABEL,
          type: 'url',
          autocomplete: 'off',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_START_ID,
          label: TASK_START_LABEL,
          type: 'date',
          required: 'true',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_END_ID,
          label: TASK_END_LABEL,
          type: 'date',
          required: 'true',
        },
      },
      {
        type: 'select',
        attributes: {
          id: TASK_STATUS_ID,
          label: TASK_STATUS_LABEL,
          required: 'true',
        },
        options: TASK_STATUS_OPTIONS,
      },
      {
        type: 'buttonGroup',
        children: [
          {
            type: 'button',
            attributes: {
              type: 'button',
              id: TASK_DELETE_BUTTON_ID,
            },
            content: TASK_DELETE_BUTTON_LABEL,
          },
          {
            type: 'button',
            attributes: {
              type: 'submit',
              style: 'flex-grow: 1;',
            },
            content: TASK_EDIT_BUTTON_LABEL,
          },
        ],
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const taskIdInput = this.dialog.querySelector(
      `#${TASK_ID_ID}`,
    ) as HTMLInputElement;

    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;

    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;

    const startInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;

    startInput.addEventListener('change', () => {
      endInput.min = startInput.value;
    });

    const endInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;

    endInput.addEventListener('change', () => {
      startInput.max = endInput.value;
    });

    const statusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      const taskData: EditTaskData = {
        id: taskIdInput.value,
        name: taskNameInput.value,
        link: taskLinkInput.value,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
        status: statusInput.value as TaskStatus,
      };

      if (statusInput.value === 'done' && taskData.end > new Date()) {
        taskData.end = roundDateToWeekday(new Date(), 'previousFriday');
      }

      callback(taskData);

      this.closeDialog();
    });

    const deleteButton = this.dialog.querySelector(
      `#${TASK_DELETE_BUTTON_ID}`,
    ) as HTMLButtonElement;

    deleteButton.addEventListener('click', () => {
      const taskData: EditTaskData = {
        id: taskIdInput.value,
        name: taskNameInput.value,
        link: taskLinkInput.value,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
        status: statusInput.value as TaskStatus,
        delete: true,
      };

      // Check if the task was marked as "done" and update the end date if necessary
      if (statusInput.value === 'done' && taskData.end > new Date()) {
        taskData.end = new Date();
      }

      callback(taskData);

      this.closeDialog();
    });
  }

  setCurrentTask(task: EditTaskData): void {
    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;
    taskNameInput.value = task.name;

    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;
    taskLinkInput.value = task.link;

    const startInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;
    startInput.value = task.start.toISOString().split('T')[0];
    startInput.max = task.end.toISOString().split('T')[0];

    if (task.min) {
      startInput.min = task.min.toISOString().split('T')[0];
    }

    const endInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;
    endInput.value = task.end.toISOString().split('T')[0];
    endInput.min = task.start.toISOString().split('T')[0];

    if (task.max) {
      endInput.max = task.max.toISOString().split('T')[0];
    }

    const statusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;
    statusInput.value = task.status;

    const taskIdInput = this.dialog.querySelector(
      `#${TASK_ID_ID}`,
    ) as HTMLInputElement;
    taskIdInput.value = task.id;
  }

  public openDialogWithData(taskData: {
    id: string;
    name: string;
    link: string;
    start: Date;
    end: Date;
    status: TaskStatus;
    min: Date;
    max: Date;
    statusHistory?: { status: TaskStatus; timestamp: number }[];
  }): void {
    this.setCurrentTask(taskData);
    this.openDialog();

    const existingStatusHistory = this.dialog.querySelector('.status-history');
    if (existingStatusHistory) {
      existingStatusHistory.remove();
    }

    const existingStatusTimelineBar = this.dialog.querySelector(
      '.status-timeline-bar',
    );
    if (existingStatusTimelineBar) {
      existingStatusTimelineBar.remove();
    }

    if (taskData.statusHistory && taskData.statusHistory.length > 0) {
      const statusHistoryElement = this.renderStatusHistory(taskData);
      this.dialog.appendChild(statusHistoryElement);

      const statusTimelineBar = this.renderStatusTimelineBar(
        taskData.statusHistory,
        taskData.start,
        taskData.end,
      );
      this.dialog.appendChild(statusTimelineBar);
    }
  }

  private renderStatusHistory(taskData: {
    id: string;
    name: string;
    link: string;
    start: Date;
    end: Date;
    status: TaskStatus;
    min: Date;
    max: Date;
    statusHistory?: { status: TaskStatus; timestamp: number }[];
  }): HTMLElement {
    const statusHistoryContainer = document.createElement('div');
    statusHistoryContainer.classList.add('status-history');

    statusHistoryContainer.appendChild(this.createHrElement());
    statusHistoryContainer.appendChild(this.createStatusHistoryTitle());

    const statusHistoryList = document.createElement('ul');
    if (taskData.statusHistory) {
      taskData.statusHistory.forEach((history, index) => {
        const listItem = this.createStatusHistoryListItem(
          history,
          index,
          taskData,
        );
        statusHistoryList.appendChild(listItem);
      });
    }

    statusHistoryContainer.appendChild(statusHistoryList);
    return statusHistoryContainer;
  }

  private createHrElement(): HTMLHRElement {
    return document.createElement('hr');
  }

  private createStatusHistoryTitle(): HTMLHeadingElement {
    const statusHistoryTitle = document.createElement('h3');
    statusHistoryTitle.innerText = 'Status History';
    return statusHistoryTitle;
  }

  private createStatusHistoryListItem(
    history: { status: TaskStatus; timestamp: number },
    index: number,
    taskData: {
      id: string;
      name: string;
      link: string;
      start: Date;
      end: Date;
      status: TaskStatus;
      min: Date;
      max: Date;
      statusHistory?: { status: TaskStatus; timestamp: number }[];
    },
  ): HTMLLIElement {
    const listItem = document.createElement('li');
    listItem.style.position = 'relative';

    const statusSpan = this.createStatusSpan(history.status);
    listItem.appendChild(statusSpan);

    const date = new Date(history.timestamp);
    const weekNumber = this.getWeekNumber(date);
    listItem.appendChild(
      document.createTextNode(
        ` - ${date.toLocaleString()} (Week ${weekNumber})`,
      ),
    );

    const editButton = this.createEditButton(date, history, taskData);
    const deleteButton = this.createDeleteButton(index, taskData);

    listItem.appendChild(editButton);
    listItem.appendChild(deleteButton);

    listItem.addEventListener('mouseover', () => {
      editButton.style.display = 'inline';
      deleteButton.style.display = 'inline';
      editButton.style.cursor = 'pointer';
      deleteButton.style.cursor = 'pointer';
    });

    listItem.addEventListener('mouseout', () => {
      editButton.style.display = 'none';
      deleteButton.style.display = 'none';
    });

    return listItem;
  }

  private createStatusSpan(status: TaskStatus): HTMLSpanElement {
    const statusSpan = document.createElement('span');
    statusSpan.classList.add('label', getStatusClass(status));
    statusSpan.innerText = status;
    return statusSpan;
  }

  private createEditButton(
    date: Date,
    history: { status: TaskStatus; timestamp: number },
    taskData: {
      id: string;
      name: string;
      link: string;
      start: Date;
      end: Date;
      status: TaskStatus;
      min: Date;
      max: Date;
      statusHistory?: { status: TaskStatus; timestamp: number }[];
    },
  ): HTMLButtonElement {
    const editButton = document.createElement('button');
    editButton.innerHTML = '✏️';
    editButton.style.position = 'absolute';
    editButton.style.right = '40px';
    editButton.style.display = 'none';
    editButton.style.background = 'transparent';
    editButton.style.border = 'none';
    editButton.style.outline = 'none';
    editButton.addEventListener('click', () => {
      const newDate = prompt(
        'Enter new date (YYYY-MM-DD):',
        date.toISOString().split('T')[0],
      );
      if (newDate) {
        history.timestamp = new Date(newDate).getTime();
        this.openDialogWithData({ ...taskData });
      }
    });
    return editButton;
  }

  private createDeleteButton(
    index: number,
    taskData: {
      id: string;
      name: string;
      link: string;
      start: Date;
      end: Date;
      status: TaskStatus;
      min: Date;
      max: Date;
      statusHistory?: { status: TaskStatus; timestamp: number }[];
    },
  ): HTMLButtonElement {
    const deleteButton = document.createElement('button');
    deleteButton.innerHTML = '🗑️';
    deleteButton.style.position = 'absolute';
    deleteButton.style.right = '10px';
    deleteButton.style.display = 'none';
    deleteButton.style.background = 'transparent';
    deleteButton.style.border = 'none';
    deleteButton.style.outline = 'none';
    deleteButton.addEventListener('click', () => {
      if (confirm('Are you sure you want to delete this status entry?')) {
        if (taskData.statusHistory) {
          taskData.statusHistory.splice(index, 1);
          this.openDialogWithData({ ...taskData });
        }
      }
    });
    return deleteButton;
  }

  private renderStatusTimelineBar(
    statusHistory: { status: TaskStatus; timestamp: number }[],
    startDate: Date,
    endDate: Date,
  ): HTMLElement {
    const timelineContainer = document.createElement('div');
    timelineContainer.classList.add('status-timeline-bar');
    timelineContainer.style.display = 'flex';
    timelineContainer.style.height = '22px';
    timelineContainer.style.width = '100%';
    timelineContainer.style.borderRadius = '5px';

    timelineContainer.style.background = this.generateStatusHistoryGradient(
      statusHistory,
      startDate,
      endDate,
    );

    return timelineContainer;
  }

  public generateStatusHistoryGradient(
    statusHistory: { status: TaskStatus; timestamp: number }[],
    startDate: Date,
    endDate: Date,
  ): string {
    const totalDuration = this.calculateBusinessDays(startDate, endDate);
    let currentPercentage = 0;
    const gradientParts = statusHistory.map((history, index) => {
      const nextTimestamp =
        index < statusHistory.length - 1
          ? statusHistory[index + 1].timestamp
          : endDate.getTime();
      const duration = this.calculateBusinessDays(
        new Date(history.timestamp),
        new Date(nextTimestamp),
      );
      const percentage = (duration / totalDuration) * 100;
      const gradientPart = `${getStatusColor(history.status)} ${currentPercentage}%, ${getStatusColor(history.status)} ${currentPercentage + percentage}%`;
      currentPercentage += percentage;
      return gradientPart;
    });
    return `linear-gradient(to right, ${gradientParts.join(', ')})`;
  }

  private calculateBusinessDays(startDate: Date, endDate: Date): number {
    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Exclude weekends
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  }

  private getWeekNumber(date: Date): number {
    const startOfChart = new Date(this.chartStartDate);
    const dayOfWeek = startOfChart.getDay() || 7; // Get day of the week, with Monday as 1 and Sunday as 7
    const adjustedStart = new Date(startOfChart);
    adjustedStart.setDate(startOfChart.getDate() - (dayOfWeek - 1)); // Adjust start date to the nearest Monday

    const pastDays = (date.getTime() - adjustedStart.getTime()) / 86400000;
    return Math.floor(pastDays / 7) + 1;
  }
}
