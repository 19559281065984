export type roundDirection = 'nextMonday' | 'previousFriday';

/**
 * Rounds a given date to the nearest specified weekday.
 *
 * If the date falls on a weekday (Monday to Friday), it returns the original date.
 * If the date falls on a weekend (Saturday or Sunday), it rounds the date to either
 * the next Monday or the previous Friday based on the specified direction.
 *
 * @param date - The date to be rounded.
 * @param direction - The direction to round the date. It can be either 'nextMonday' or 'previousFriday'.
 * @returns The rounded date.
 */
export default function roundDateToWeekday(
  date: Date,
  direction: roundDirection,
): Date {
  if (date.getDay() > 0 && date.getDay() < 6) {
    return date;
  }

  const newDate = new Date(date);

  if (direction === 'nextMonday') {
    while (newDate.getDay() !== 1) {
      newDate.setDate(newDate.getDate() + 1);
    }
  } else {
    while (newDate.getDay() !== 5) {
      newDate.setDate(newDate.getDate() - 1);
    }
  }

  return newDate;
}
