import calculateDurationInDays from './calculateDurationInDays';
import formatDate from './formatDate';

/**
 * Generates an array of week objects between the given start and end dates.
 *
 * @param start - The start date of the range.
 * @param end - The end date of the range.
 * @returns An array of objects, each representing a week with a name property.
 */
export default function generateWeeksArray(
  start: Date,
  end: Date,
): { name: string }[] {
  const days = calculateDurationInDays(start, end);
  const weeks = Math.ceil(days / 7);

  return Array.from({ length: weeks }, (_, i) => {
    const startDate = new Date(start);
    startDate.setDate(startDate.getDate() + i * 7);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    return {
      start,
      end,
      name: `Week ${i + 1} (${formatDate(startDate)} - ${formatDate(endDate)})`,
    };
  });
}
