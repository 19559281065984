import { Chart } from '@/classes/Chart';
import convertStringToChart from './convertStringToChart';

/**
 * Loads the chart data from the local storage.
 *
 * @returns {Chart | null} The chart object if it exists in local storage, otherwise null.
 */
export default function loadChartFromStorage(): Chart | null {
  const data = localStorage.getItem('chart');
  if (!data) return null;

  return convertStringToChart(data);
}
