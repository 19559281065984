import { Task } from '@/classes/Task';
import { Developer } from '@/classes/Developer';
import { Chart } from '@/classes/Chart';
import fromBinaryStr from './fromBinaryStr';

/**
 * Converts a base64 encoded string to a Chart object.
 *
 * @param data - The base64 encoded string representing the chart data.
 * @returns A Chart object constructed from the parsed data.
 * @throws Will throw an error if the app container is not found.
 */
export default function convertStringToChart(data: string): Chart {
  const parsedData = JSON.parse(fromBinaryStr(atob(data)));
  const container = document.getElementById('app');

  if (!container) throw new Error('App container not found');

  return new Chart({
    container: container,
    timestamp: parsedData.timestamp,
    name: parsedData.name,
    start: new Date(parsedData.start),
    end: new Date(parsedData.end),
    developers: parsedData.developers.map((developerData: any) => {
      const developer = new Developer({
        name: developerData.name,
        tasks: developerData.tasks.map(
          (taskData: any) =>
            new Task({
              name: taskData.name,
              link: taskData.link,
              start: new Date(taskData.start),
              end: new Date(taskData.end),
              status: taskData.status,
              statusHistory: taskData.statusHistory
                ? taskData.statusHistory.map((history: any) => ({
                    status: history.status,
                    timestamp: history.timestamp,
                  }))
                : [],
            }),
        ),
        ptos: developerData.ptos.map((ptoData: any) => {
          return {
            type: ptoData.type,
            start: new Date(ptoData.start),
            end: new Date(ptoData.end),
          };
        }),
      });

      return developer;
    }),
  });
}
