import { Chart } from '@/classes/Chart';
import convertChartToString from './convertChartToString';

/**
 * Saves the provided chart to local storage.
 *
 * @param {Chart} chart - The chart object to be saved.
 * @returns {void}
 */
export default function saveChartToStorage(chart: Chart): void {
  const data = convertChartToString(chart);
  localStorage.setItem('chart', data);
}
