import roundDateToWeekday from '@/utilities/roundDateToWeekday';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import { Developer } from '@/classes/Developer';
import { TaskData, TaskStatus } from '@/classes/Task';

import { AbstractDialog } from './AbstractDialog';

export interface CreateTaskData extends TaskData {
  developerId: string;
}

import {
  DEVELOPER_SELECT_ID,
  TASK_NAME_ID,
  TASK_LINK_ID,
  TASK_START_ID,
  TASK_END_ID,
  TASK_STATUS_ID,
  DEVELOPER_LABEL,
  TASK_NAME_LABEL,
  TASK_LINK_LABEL,
  TASK_START_LABEL,
  TASK_END_LABEL,
  TASK_STATUS_LABEL,
  ADD_TASK_BUTTON_LABEL,
  TASK_STATUS_OPTIONS,
} from '@/constants';

export class AddTaskDialog extends AbstractDialog {
  constructor(
    container: HTMLElement,
    developers: Developer[],
    min: Date,
    max: Date,
    callback: (data: CreateTaskData) => void,
  ) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'select',
        attributes: {
          id: DEVELOPER_SELECT_ID,
          name: DEVELOPER_SELECT_ID,
          label: DEVELOPER_LABEL,
          required: 'true',
        },
        options: developers.map((dev, index) => ({
          value: dev.getId(),
          text: dev.getName(),
          selected: index === 0,
        })),
      },
      {
        type: 'input',
        attributes: {
          type: 'text',
          id: TASK_NAME_ID,
          name: TASK_NAME_ID,
          autocomplete: 'off',
          autofocus: 'true',
          required: 'true',
          label: TASK_NAME_LABEL,
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'text',
          id: TASK_LINK_ID,
          name: TASK_LINK_ID,
          autocomplete: 'off',
          required: 'true',
          label: TASK_LINK_LABEL,
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: TASK_START_ID,
          name: TASK_START_ID,
          required: 'true',
          label: TASK_START_LABEL,
          min: min.toISOString().split('T')[0],
          max: max.toISOString().split('T')[0],
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: TASK_END_ID,
          name: TASK_END_ID,
          required: 'true',
          label: TASK_END_LABEL,
          min: min.toISOString().split('T')[0],
          max: max.toISOString().split('T')[0],
        },
      },
      {
        type: 'select',
        attributes: {
          id: TASK_STATUS_ID,
          name: TASK_STATUS_ID,
          required: 'true',
          label: TASK_STATUS_LABEL,
        },
        options: TASK_STATUS_OPTIONS,
      },
      {
        type: 'button',
        attributes: { type: 'submit' },
        content: ADD_TASK_BUTTON_LABEL,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const developerSelectInput = this.dialog.querySelector(
      `#${DEVELOPER_SELECT_ID}`,
    ) as HTMLSelectElement;
    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;
    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;
    const taskStartInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;
    const taskEndInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;
    const taskStatusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;

    taskStartInput.addEventListener('change', () => {
      taskEndInput.min = taskStartInput.value;
    });

    taskEndInput.addEventListener('change', () => {
      taskStartInput.max = taskEndInput.value;
    });

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      const startDate = roundDateToWeekday(
        new Date(taskStartInput.value),
        'nextMonday',
      );
      const endDate = roundDateToWeekday(
        new Date(taskEndInput.value),
        'previousFriday',
      );

      callback({
        developerId: developerSelectInput.value,
        name: taskNameInput.value,
        link: taskLinkInput.value,
        start: startDate,
        end: endDate,
        status: taskStatusInput.value as TaskStatus,
        statusHistory: [
          {
            status: TaskStatus.Backlog,
            timestamp: startDate.getTime(),
          },
        ],
      });

      taskNameInput.value = '';
      taskLinkInput.value = '';
      taskStartInput.value = '';
      taskEndInput.value = '';
      taskEndInput.min = min.toISOString().split('T')[0];
      taskEndInput.max = max.toISOString().split('T')[0];
      taskStartInput.min = min.toISOString().split('T')[0];
      taskStartInput.max = max.toISOString().split('T')[0];
      taskStatusInput.value = TaskStatus.Backlog;

      this.closeDialog();
    });
  }

  public openDialogWithData(taskData: CreateTaskData): void {
    const developerSelectInput = this.dialog.querySelector(
      `#${DEVELOPER_SELECT_ID}`,
    ) as HTMLSelectElement;
    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;
    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;
    const taskStartInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;
    const taskEndInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;
    const taskStatusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;

    developerSelectInput.value = taskData.developerId;
    taskNameInput.value = taskData.name;
    taskLinkInput.value = taskData.link;
    taskStartInput.value = taskData.start.toISOString().split('T')[0];
    taskEndInput.value = taskData.end.toISOString().split('T')[0];
    taskStatusInput.value = taskData.status;

    this.openDialog();
  }
}
